<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col
        :span="12"
        :offset="6"
        :xs="{ span: 24, offset: 0 }"
      >
        <el-button
          v-for="test in tests"
          :key="test._id"
          type="primary"
          round
          @click="openTest(test)"
        >
          {{ test.description }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ElButton from 'element-ui/lib/button';

export default {
  name: "tests",
  components: {
    ElButton,
  },
  computed: {
    ...mapGetters({
      tests: 'getTests'
    })
  },
  methods: {
    ...mapActions({
      getTests: 'getTests'
    }),
    openTest(test) {
      this.$router.push({
        name: 'test',
        params: {
          testId: test._id
        }
      });
    }
  },
  mounted() {
    this.getTests();
  }
};
</script>

<style lang="scss" scoped>
.test-progress {
  position: fixed;
  width: 200px;
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;

    .el-button {
      width: 100%;
    }
  }
}

.el-button {
  width: 100%;
  margin-left: 0 !important;
  margin-bottom: 10px;
}
</style>
